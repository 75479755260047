<script>
import axios from "axios";

export default {
  data() {
    return {
      kpi: null,
      is_loading: false
    }
  },
  methods: {
    async getKpi() {
      try {

        this.is_loading = true

        const {start_date, end_date} = this.$route.query;

        let response = await axios.get('/code/kpi/', {
          params: (start_date && end_date) ? {
            date_from: start_date,
            date_to: end_date,
          } : {}
        })

        this.kpi = response.data

        if (!this.kpi.manager_statistics) {
          this.kpi.manager_statistics = []
        }

        this.kpi.manager_statistics = this.kpi.manager_statistics.map(i => {
          let order_connection_percentage = parseFloat(i.order_connected_percentage || 0)
          let act_connection_percentage = parseFloat(i.act_connected_percentage || 0)
          let smgs_connection_percentage = parseFloat(i.smgs_connected_percentage || 0)
          let filled_applications_percentage = parseFloat(i.filled_applications_percentage || 0)

          return {
            ...i,
            average_percentage: ((order_connection_percentage + act_connection_percentage + smgs_connection_percentage + filled_applications_percentage) / 4)
          }
        })

        this.is_loading = false
      } catch {
        this.is_loading = false
        alert("error loading Codes KPI")
      }
    },
  },
  computed: {
    date_from() {
      return this.$route.query.start_date
    },
    date_to() {
      return this.$route.query.end_date
    },
  },
  watch: {
    '$route.query': {
      handler(newQuery, oldQuery) {
        if (
            newQuery.start_date !== oldQuery.start_date ||
            newQuery.end_date !== oldQuery.end_date
        ) {
          this.getKpi();
        }
      },
      deep: true
    },
  },
  async mounted() {
    await this.getKpi()
  }
}
</script>

<template>
  <b-card no-body class="pb-0">
    <b-card-header class="border-0 align-items-center d-flex">
      <b-card-title class="mb-0 flex-grow-1">Operations - Codes</b-card-title>
    </b-card-header>

    <b-card-header class="p-0 border-0">

      <!--      {{ kpi }}-->

      <b-row v-if="kpi" class="g-0">
        <b-col class="bg-light" cols="6" xxl="2" xl="4" sm="4">
          <div class="p-3 border border-dashed border-start-0">
            <h5 class="mb-1">
              {{
                parseFloat(kpi.total_applications || 0).toLocaleString(undefined, {
                  minimumFractionDigits: 0, maximumFractionDigits: 0
                })
              }}
              <span class="text-black-50 ms-1 fs-14">
                <i class="mdi mdi-slash-forward"></i>
                {{
                  parseFloat(kpi.total_codes || 0).toLocaleString(undefined, {
                    minimumFractionDigits: 0, maximumFractionDigits: 0
                  })
                }}
                codes
              </span>
            </h5>
            <p class="text-muted mb-0">Total applications</p>
          </div>
        </b-col>
        <b-col class="bg-light" cols="6" xxl="2" xl="4" sm="4">
          <div class="p-3 border border-dashed border-start-0">
            <h5 class="text-success mb-1">
              {{
                parseFloat(kpi.filled_applications_percentage || 0).toLocaleString(undefined, {
                  minimumFractionDigits: 1, maximumFractionDigits: 1
                })
              }}%
              <span class="text-black-50 ms-1 fs-14">
                <i class="mdi mdi-slash-forward"></i>
                {{
                  parseFloat(kpi.filled_applications || 0).toLocaleString(undefined, {
                    minimumFractionDigits: 0, maximumFractionDigits: 0
                  })
                }}
              </span>
            </h5>
            <p class="text-muted mb-0">Filled percentage</p>
          </div>
        </b-col>

        <b-col class="bg-soft-light" cols="6" xxl="2" xl="4" sm="4">
          <div class="p-3 border border-dashed border-start-0">
            <h5 class="text-success mb-1">
              {{
                parseFloat(kpi.applications_with_prices_percentage || 0).toLocaleString(undefined, {
                  minimumFractionDigits: 1, maximumFractionDigits: 1
                })
              }}%
              <span class="text-black-50 ms-1 fs-14">
                <i class="mdi mdi-slash-forward"></i>
                {{
                  parseFloat(kpi.applications_with_prices || 0).toLocaleString(undefined, {
                    minimumFractionDigits: 0, maximumFractionDigits: 0
                  })
                }}
              </span>
            </h5>
            <p class="text-muted mb-0">Applications with prices</p>
          </div>
        </b-col>
        <b-col class="bg-soft-light" cols="6" xxl="2" xl="4" sm="4">
          <div class="p-3 border border-dashed border-start-0">
            <h5 class="text-success mb-1">
              {{
                parseFloat(kpi.order_connected_percentage || 0).toLocaleString(undefined, {
                  minimumFractionDigits: 1, maximumFractionDigits: 1
                })
              }}%
              <span class="text-black-50 ms-1 fs-14">
                <i class="mdi mdi-slash-forward"></i>
                {{
                  parseFloat(kpi.order_connected_codes || 0).toLocaleString(undefined, {
                    minimumFractionDigits: 0, maximumFractionDigits: 0
                  })
                }}
              </span>
            </h5>
            <p class="text-muted mb-0">Order connection</p>
          </div>
        </b-col>
        <b-col class="bg-soft-light" cols="6" xxl="2" xl="4" sm="4">
          <div class="p-3 border border-dashed border-start-0">

            <h5 class="text-success mb-1">
              {{
                parseFloat(kpi.act_connected_percentage || 0).toLocaleString(undefined, {
                  minimumFractionDigits: 1, maximumFractionDigits: 1
                })
              }}%
              <span class="text-black-50 ms-1 fs-14">
                <i class="mdi mdi-slash-forward"></i>
                {{
                  parseFloat(kpi.act_connected_codes || 0).toLocaleString(undefined, {
                    minimumFractionDigits: 0, maximumFractionDigits: 0
                  })
                }}
              </span>
            </h5>

            <p class="text-muted mb-0">Act connection</p>
          </div>
        </b-col>
        <b-col class="bg-soft-light" cols="6" xxl="2" xl="4" sm="4">
          <div class="p-3 border border-dashed border-start-0">

            <h5 class="text-success mb-1">
              {{
                parseFloat(kpi.smgs_connected_percentage || 0).toLocaleString(undefined, {
                  minimumFractionDigits: 1, maximumFractionDigits: 1
                })
              }}%
              <span class="text-black-50 ms-1 fs-14">
                <i class="mdi mdi-slash-forward"></i>
                {{
                  parseFloat(kpi.smgs_connected_codes || 0).toLocaleString(undefined, {
                    minimumFractionDigits: 0, maximumFractionDigits: 0
                  })
                }}
              </span>
            </h5>

            <p class="text-muted mb-0">SMGS connection</p>
          </div>
        </b-col>
      </b-row>

    </b-card-header>

    <b-card-body v-if="kpi">
      <div class="table-responsive">
        <table class="table table-nowrap mb-0">
          <thead>
          <tr class="fs-14">
            <th scope="col">Manager</th>
            <th class="bg-light border-end" scope="col">Total Applications</th>
            <th class="bg-light" scope="col">Filled Applications</th>
            <th scope="col">Applications With Prices</th>
            <th scope="col">Total Codes</th>
            <th scope="col">Order Connection</th>
            <th scope="col">Acts Connection</th>
            <th scope="col">SMGS Connection</th>
            <th scope="col">Total Average</th>
          </tr>
          </thead>
          <tbody>
          <template v-if="is_loading">
            <tr class="align-middle">
              <th colspan="8">
                <b-spinner style="width: 15px; height: 15px"></b-spinner>
              </th>
            </tr>
          </template>
          <template v-else>
            <tr v-for="(manager, index) in (kpi.manager_statistics || [])"
                :key="`manager_${manager.manager ? manager.manager : index}`"
                class="fs-14"
            >
              <th scope="row"><a class="fw-semibold" :class="{
              'text-danger': !manager.manager.id
            }">
                {{ manager.manager.id ? manager.manager.username : 'Unknown Manager' }}</a>
              </th>
              <td class="bg-light border-end">
                <div>
                <span>{{
                    parseFloat(manager.total_applications || 0).toLocaleString(undefined, {
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 0
                    })
                  }}</span>
                  <span class="text-muted">
                  <i class="mdi mdi-approximately-equal mx-1"></i>{{
                      parseFloat(((manager.total_applications || 0) / (kpi.total_applications || 1)) * 100).toLocaleString(undefined, {
                        minimumFractionDigits: 1,
                        maximumFractionDigits: 1
                      })
                    }}% of total
                </span>
                </div>

              </td>
              <td class="bg-light">
                <div>
                <span>{{
                    parseFloat(manager.filled_applications_percentage || 0).toLocaleString(undefined, {
                      minimumFractionDigits: 1,
                      maximumFractionDigits: 1
                    })
                  }}%
                </span>
                  <span class="text-muted">
                  <i class="mdi mdi-slash-forward"></i>{{
                      parseFloat(manager.filled_applications || 0).toLocaleString(undefined, {
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 0
                      })
                    }}
                </span>
                </div>
              </td>
              <td>
                <div>
                  <span>
                    {{
                      parseFloat(manager.applications_with_prices || 0).toLocaleString(undefined, {
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 0
                      })
                    }}
                  </span>
                  <span class="text-muted">
                    <i class="mdi mdi-approximately-equal"></i>
                    {{
                      parseFloat(((manager.applications_with_prices || 0) / (manager.total_applications || 1)) * 100).toLocaleString(undefined, {
                        minimumFractionDigits: 1,
                        maximumFractionDigits: 1
                      })
                    }}
                    % of total
                  </span>
                </div>
              </td>
              <td>
                <div>
                <span>{{
                    parseFloat(manager.total_codes || 0).toLocaleString(undefined, {
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 0
                    })
                  }}</span>
                  <span class="text-muted">
                  <i class="mdi mdi-approximately-equal"></i>{{
                      parseFloat(((manager.total_codes || 0) / (kpi.total_codes || 1)) * 100).toLocaleString(undefined, {
                        minimumFractionDigits: 1,
                        maximumFractionDigits: 1
                      })
                    }}% of total
                </span>
                </div>

              </td>
              <td>
                <div>
                <span>{{
                    parseFloat(manager.order_connected_percentage || 0).toLocaleString(undefined, {
                      minimumFractionDigits: 1,
                      maximumFractionDigits: 1
                    })
                  }}%
                </span>
                  <span class="text-muted">
                  <i class="mdi mdi-slash-forward"></i>{{
                      parseFloat(manager.order_connected_codes || 0).toLocaleString(undefined, {
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 0
                      })
                    }}
                </span>
                </div>
              </td>
              <td>
                <div>
                <span>{{
                    parseFloat(manager.act_connected_percentage || 0).toLocaleString(undefined, {
                      minimumFractionDigits: 1,
                      maximumFractionDigits: 1
                    })
                  }}%
                </span>
                  <span class="text-muted">
                  <i class="mdi mdi-slash-forward"></i>{{
                      parseFloat(manager.act_connected_codes || 0).toLocaleString(undefined, {
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 0
                      })
                    }}
                </span>
                </div>
              </td>
              <td>
                <div>
                <span>{{
                    parseFloat(manager.smgs_connected_percentage || 0).toLocaleString(undefined, {
                      minimumFractionDigits: 1,
                      maximumFractionDigits: 1
                    })
                  }}%
                </span>
                  <span class="text-muted">
                  <i class="mdi mdi-slash-forward"></i>{{
                      parseFloat(manager.smgs_connected_codes || 0).toLocaleString(undefined, {
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 0
                      })
                    }} codes
                </span>
                </div>
              </td>
              <th class="text-success">
                {{
                  parseFloat(manager.average_percentage || 0).toLocaleString(undefined, {
                    minimumFractionDigits: 1, maximumFractionDigits: 1
                  })
                }}%
              </th>
            </tr>
          </template>
          </tbody>
        </table>
      </div>
    </b-card-body>

  </b-card>

</template>

<style scoped>

</style>